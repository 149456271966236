import React from 'react';

const SvgApplication = props => (
  <svg viewBox="0 0 25 20" fill="none" {...props}>
    <path
      d="M20.9999 8.90625V12.4688C20.9999 12.5832 20.9864 12.6973 20.9596 12.8087L19.7453 17.8556C19.584 18.5261 18.9722 19 18.2678 19H10.3749C10.1355 19 9.89951 18.9446 9.68617 18.8384C9.47282 18.7321 9.2882 18.578 9.14737 18.3887L4.29034 11.8574C3.79731 11.1945 3.94719 10.2661 4.62511 9.78392C5.3031 9.30172 6.2524 9.44834 6.74544 10.1113L7.94637 11.7263V1.48438C7.94637 0.664592 8.62591 0 9.46423 0C10.3025 0 10.9821 0.664592 10.9821 1.48438V8.90625H11.2857V7.42188C11.2857 6.60209 11.9652 5.9375 12.8035 5.9375C13.6418 5.9375 14.3214 6.60209 14.3214 7.42188V8.90625H14.6249V8.01562C14.6249 7.19584 15.3045 6.53125 16.1428 6.53125C16.9811 6.53125 17.6607 7.19584 17.6607 8.01562V8.90625H17.9642C17.9642 8.08647 18.6438 7.42188 19.4821 7.42188C20.3204 7.42188 20.9999 8.08647 20.9999 8.90625ZM11.2857 11.875H10.9821V15.4375H11.2857V11.875ZM14.6249 11.875H14.3214V15.4375H14.6249V11.875ZM17.9642 11.875H17.6607V15.4375H17.9642V11.875Z"
      fill="black"
    />
  </svg>
);

export default SvgApplication;
