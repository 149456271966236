import React from 'react';
import './scss/App.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from './routes';
import { ProgressProvider } from './context/Progress';
import { ApiProvider } from './context/Api';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';

function App() {
  const { i18n } = useTranslation();
  const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };
  initializeApp(config);
  setUserProperties(getAnalytics(), { platform: 'web' });

  const router = createBrowserRouter(routes);

  return (
    <ApiProvider i18n={i18n}>
      <ProgressProvider>
        <div className="App" data-version={process.env.REACT_APP_VERSION}>
          <RouterProvider router={router} />
        </div>
      </ProgressProvider>
    </ApiProvider>
  );
}

var deferredPrompt;
window.addEventListener('beforeinstallprompt', function(e) {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;

  console.log('beforeinstallprompt');

  showAddToHomeScreen();
});
function showAddToHomeScreen() {
  var a2hsCookie = getCookie('a2hs');
  var a2hsPrompt = document.querySelector('.ad2hs-prompt');
  if (!a2hsCookie && a2hsPrompt) {
    a2hsPrompt.style.display = 'block';

    var a2hsAdd = document.querySelector('.ad2hs-prompt .add-prompt');
    a2hsAdd.addEventListener('click', addToHomeScreen);
    var a2hsDismiss = document.querySelector('.ad2hs-prompt .dismiss-prompt');
    a2hsDismiss.addEventListener('click', dismissPrompt);
  }
}
function addToHomeScreen() {
  var a2hsBtn = document.querySelector('.ad2hs-prompt'); // hide our user interface that shows our A2HS button
  a2hsBtn.style.display = 'none'; // Show the prompt
  deferredPrompt.prompt(); // Wait for the user to respond to the prompt
  deferredPrompt.userChoice.then(function(choiceResult) {
    if (choiceResult.outcome === 'accepted') {
      console.log('User accepted the A2HS prompt');
    } else {
      console.log('User dismissed the A2HS prompt');
    }
    logEvent(getAnalytics(), 'app_install', {
      action: choiceResult.outcome || 'dismissed'
    });
    deferredPrompt = null;
  });
}
function dismissPrompt() {
  var a2hsBtn = document.querySelector('.ad2hs-prompt'); // hide our user interface that shows our A2HS button
  a2hsBtn.style.display = 'none'; // Show the prompt
  deferredPrompt = null;

  // set cookie so user isn't prompted again
  setCookie('a2hs', 'dismissed', 180);
}
function getCookie(name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}
function setCookie(name, value, days) {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString();
}

export default App;
