import showdown from 'showdown';

// Clean up quotes by removing left/right quotes for generic quotes
showdown.extension('singlequotes', {
  type: 'output',
  regex: /[‘’]/g,
  replace: "'"
});
showdown.extension('doublequotes', {
  type: 'output',
  regex: /[“”„]/g,
  replace: '"'
});
const md = new showdown.Converter({
  extensions: ['singlequotes', 'doublequotes']
});

export default md;
