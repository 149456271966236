import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Button, InputGroup, Form, Row, Col } from 'react-bootstrap';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useTranslation } from 'react-i18next';
import { ProgressContext } from '../context/Progress';
import { ApiContext } from '../context/Api';

const USER_ANSWERS = 'userAnswers';

function Done({ data }) {
  const { apiService } = useContext(ApiContext);
  const [shareAnswer, setShareAnswer] = useState(false);
  var userEmail = '';

  const { t } = useTranslation();
  const navigate = useNavigate();
  const progress = useContext(ProgressContext);

  async function doneAction() {
    if (shareAnswer) {
      try {
        await _sendEmail();
      } catch (error) {
        console.log(`Error sending email: ${error}`);
      }
    }
    await progress.completeLesson(data.slug);
    logEvent(getAnalytics(), 'complete_lesson', {
      course: data.course.slug,
      lesson: data.slug
    });
    navigate('../../');
  }

  async function _sendEmail() {
    const userAnswers = JSON.parse(localStorage.getItem(USER_ANSWERS));

    if (!data.blocks) {
      return;
    }

    if (!userAnswers) {
      return;
    }

    const lessonId = data.id;
    const lessonAnswers = userAnswers.filter(e => e.lessonId === lessonId);

    if (!lessonAnswers) {
      return;
    }

    const questionAnswers = [];
    const lessonTitle = data.name;
    const questionBlocs = data.blocks.filter(e => e.type === 'questions');
    questionBlocs.forEach(questionBloc => {
      const question = questionBloc.content;

      const answer = lessonAnswers.find(e => e.lessonblock === questionBloc.id);

      questionAnswers.push({
        question,
        answer: answer ? answer.answer : ''
      });
    });

    let answerString = '';

    for (let i = 0; i < questionAnswers.length; i++) {
      const questionAnswer = questionAnswers[i];
      answerString += `
      <ol start="${i + 1}">
      <li>${questionAnswer.question} <br><br>A: ${questionAnswer.answer}</li>
      </ol>`;
    }

    const body = `${lessonTitle}<br><br> <h3>Questions</h3>${answerString}`;

    apiService
      .sendEmail(userEmail, 'New answers for lesson ' + lessonTitle, body)
      .then(response => {
        // Handle success.
        // setShowAskHelpToast(true);
      })
      .catch(error => {
        console.log('An error occurred:', error.response);
      });
  }

  return (
    <div>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="auto">
          {t('lesson.shareAnswer')}
        </Form.Label>
        <Col>
          <InputGroup
            onClick={e => {
              if (!shareAnswer) {
                setShareAnswer(true);
                setTimeout(function() {
                  document.getElementById('recipientEmail').focus();
                }, 100);
              }
            }}
          >
            <InputGroup.Checkbox
              name="recipientEmailCheckbox"
              id="recipientEmailCheckbox"
              aria-label={t('lesson.shareAnswer')}
              checked={shareAnswer}
              onChange={_ => setShareAnswer(!shareAnswer)}
            />
            <Form.Control
              placeholder={t('lesson.recipientEmail')}
              aria-label={t('lesson.recipientEmail')}
              id="recipientEmail"
              disabled={!shareAnswer}
              onChange={e => (userEmail = e.target.value)}
            />
          </InputGroup>
        </Col>
      </Form.Group>

      <div className="doneButton">
        <Button onClick={doneAction} variant="primary" className="mb-4">
          <FontAwesomeIcon icon={faCheck} />
          &nbsp; {t('lesson.done')}
        </Button>
      </div>
    </div>
  );
}
export default Done;
