import React from 'react';

export default function SectionHeading({ icon, title, blocks }) {
  return (
    <>
      {blocks ? (
        <div>
          <div className="lesson-section-title">
            {icon}
            &nbsp; {title}
          </div>
          <hr className={`section-title-hr`} />
        </div>
      ) : null}
    </>
  );
}
