import React from 'react';
import md from '../../markdown';

export default function Sharing({ block }) {
  return (
    <p
      className="paragraph-item"
      key={block.id}
      data-name={block.name}
      data-id={block.id}
      dangerouslySetInnerHTML={{
        __html: md.makeHtml(block.content)
      }}
    />
  );
}
