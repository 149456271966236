import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Nav } from 'react-bootstrap';

import { useChangeLanguage } from '../hooks';
import { ApiContext } from '../context/Api';

export default function LanguageSelect({ showLanguage, variant, nav, drop }) {
  const { locales } = useContext(ApiContext);
  const { t, i18n } = useTranslation();
  const changeLanguage = useChangeLanguage();

  const label = (
    <>
      <FontAwesomeIcon icon={faGlobe} />
      {showLanguage ? t('currentLanguage') : ''}
    </>
  );

  const dropdownAs = nav ? Nav.Item : Dropdown;
  const toggleAs = nav ? Nav.Link : Dropdown.Toggle;
  const dropDir = drop || 'down';

  const isActive = lng => lng === i18n.language;
  const onSelect = key => {
    changeLanguage(key);
  };

  const items = (locales || [])
    .filter(x => x.published)
    .map(locale => (
      <Dropdown.Item
        key={locale.code}
        eventKey={locale.code}
        disabled={isActive(locale.code)}
      >
        {locale.localName}
      </Dropdown.Item>
    ));
  return (
    <Dropdown
      className="language-select-container"
      alignRight
      onSelect={onSelect}
      drop={dropDir}
      as={dropdownAs}
    >
      <Dropdown.Toggle
        variant={variant || 'dark'}
        id="language-select"
        as={toggleAs}
      >
        {label}
      </Dropdown.Toggle>

      <Dropdown.Menu>{items}</Dropdown.Menu>
    </Dropdown>
  );
}
