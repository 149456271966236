import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap';

export default function Loader() {
  return (
    <Container className="loading">
      <FontAwesomeIcon className="fa-spin" size="5x" icon={faSpinner} />
    </Container>
  );
}
