import React, { useState, useEffect, useContext } from 'react';
import { Container } from 'react-bootstrap';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, getIn } from 'formik';
import { TextField } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import * as Yup from 'yup';

import materialUiTheme, { materialUiThemeAr } from '../materialUiTheme';
import logo from '../logo-trans.png';
import { useBodyClass, useLangParam } from '../hooks';
import { ApiContext } from '../context/Api';
import { useTranslation } from 'react-i18next';
import LanguageSelect from './LanguageSelect';

function ResetPassword() {
  useLangParam();
  const { apiService } = useContext(ApiContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { lang } = useParams();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useBodyClass('signin');

  useEffect(() => {
    if (!searchParams.code) {
      navigate(`/${lang}/forgot-password`);
    }

    const user = JSON.parse(localStorage.getItem('userInfo'));
    if (user) {
      navigate(`/${lang}/`);
    }
  }, [navigate, searchParams, lang]);

  async function onReset(values) {
    setIsLoading(true);

    apiService
      .sendPostRequest('auth/reset-password', {
        code: searchParams.code,
        password: values.password,
        passwordConfirmation: values.confirmPassword
      })
      .then(response => {
        // Handle success.
        setIsLoading(false);
        setSuccess('Your password has been changed successfully');
        console.log("Your user's password has been changed.");
      })
      .catch(error => {
        // Handle error.
        setError(error.response.data.message[0].messages[0].message);
        setIsLoading(false);
        console.log('An error occurred:', error.response);
      });
  }
  return (
    <>
      <Container>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 offset-lg-3 col-lg-6 offset-md-2 col-md-8 center-block">
              <div className="top-lang">
                <LanguageSelect showLanguage />
              </div>
              <div className="well-header text-center">
                <img
                  alt=""
                  src={logo}
                  width="80"
                  className="d-inline-block align-top logo"
                />
                <div>
                  <h3 className="heading-title text-center">
                    {t('core.progressingTogether')}
                  </h3>
                </div>
                <div>
                  <h5 className="heading-title"> {t('auth.resetPassword')} </h5>
                </div>
              </div>
              <Formik
                initialValues={{ password: '', confirmPassword: '' }}
                validationSchema={Yup.object({
                  password: Yup.string().required(t('core.required')),
                  confirmPassword: Yup.string().required(t('core.required'))
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setError(null);
                  setSuccess(null);
                  onReset(values);
                  setSubmitting(false);
                }}
              >
                {({ handleChange, errors, touched }) => (
                  <Form className="form-base">
                    <ThemeProvider
                      theme={
                        lang === 'en' ? materialUiTheme : materialUiThemeAr
                      }
                    >
                      <TextField
                        label={t('auth.password') + '*'}
                        className="form-field"
                        type="password"
                        name="password"
                        variant="filled"
                        onChange={handleChange}
                        error={Boolean(
                          getIn(touched, 'password') &&
                            getIn(errors, 'password')
                        )}
                        helperText={
                          getIn(touched, 'password') &&
                          getIn(errors, 'password')
                            ? getIn(errors, 'password')
                            : ''
                        }
                      />
                      <TextField
                        label={t('auth.confirmPassword') + '*'}
                        className="form-field"
                        type="password"
                        name="confirmPassword"
                        variant="filled"
                        onChange={handleChange}
                        error={Boolean(
                          getIn(touched, 'confirmPassword') &&
                            getIn(errors, 'confirmPassword')
                        )}
                        helperText={
                          getIn(touched, 'confirmPassword') &&
                          getIn(errors, 'confirmPassword')
                            ? getIn(errors, 'confirmPassword')
                            : ''
                        }
                      />
                    </ThemeProvider>
                    {error && <div className="alert alert-danger">{error}</div>}
                    {success && (
                      <div className="alert alert-success">{success}</div>
                    )}
                    {!isLoading ? (
                      <button
                        type="submit"
                        className="btn btn-dark btn-lg btn-block form-btn"
                      >
                        {t('auth.resetPassword')}
                      </button>
                    ) : (
                      <div
                        type="submit"
                        className="btn btn-dark btn-lg btn-block form-btn disabled"
                      >
                        <FontAwesomeIcon
                          className="fa-spin"
                          size="1x"
                          icon={faSpinner}
                        />
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
              <div className="end-text text-center">
                {t('auth.alreadyHaveAccount')}&nbsp;
                <Link className="login-link-text" id="login_id" to="../signin">
                  {t('auth.login')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
export default ResetPassword;
