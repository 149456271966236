import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Container, Button, Row } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import showdown from 'showdown';

import useLangParam from '../hooks/useLangParam';
import { ApiContext } from '../context/Api';
import NotFound from './NotFound';
import Loader from './Loader';
import NetworkError from './NetworkError';
import MainNavbar from './Navbar';

const md = new showdown.Converter();

function Course() {
  useLangParam();
  const { t } = useTranslation();
  const { apiService } = useContext(ApiContext);
  const [currentLang] = useCookies(['language']);
  const { courseSlug } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dataError, setDataError] = useState(false);
  const course = data && data.length ? data[0] : {};
  const lesson =
    course && course.lessons && course.lessons.length ? course.lessons[0] : {};

  const fetchData = useCallback(() => {
    setIsLoading(true);
    try {
      apiService
        .getCourse(
          currentLang.language ? currentLang.language : 'ar',
          courseSlug
        )
        .then(responseData => {
          setData(responseData.data.courses);
          setIsLoading(false);
          setDataError(false);
        })
        .catch(() => {
          setDataError(true);
          setIsLoading(false);
        });
    } catch (err) {
      setError('Something went wrong');
      setIsLoading(false);
    }
  }, [apiService, currentLang, courseSlug]);

  const onRefresh = useCallback(() => {
    setIsLoading(true);
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang]);

  function doneAction() {
    logEvent(getAnalytics(), 'complete_course_intro', {
      course: courseSlug
    });
  }

  let content = null;
  if (isLoading) {
    content = <Loader />;
  } else if (error) {
    content = error;
  } else if (course && course.name) {
    content = (
      <Container>
        <div className="lesson-title-box">
          <h1 className="lesson-title">{course.name}</h1>
          <p className="lesson-subtitle">{course.subtitle}</p>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: md.makeHtml(course.description)
          }}
        />
        <div className="doneButton">
          <Link onClick={doneAction} to={`${lesson.id}-${lesson.slug}`}>
            <Button variant="primary">{t('lesson.goToFirstLesson')}</Button>
          </Link>
        </div>
      </Container>
    );
  } else {
    content = <NotFound />;
  }

  if (dataError) {
    return (
      <>
        <MainNavbar />
        <NetworkError onRefresh={onRefresh} />
      </>
    );
  }
  return (
    <>
      <MainNavbar />
      <Link
        type="button"
        to="../contact"
        className="btn contact-btn btn-primary btn-circle btn-lg"
      >
        <FontAwesomeIcon icon={faCommentAlt} />
      </Link>
      <Container>
        <Row>{content}</Row>
      </Container>
    </>
  );
}

export default Course;
