import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';

const materialUiTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: blue['50'],
      main: grey['50'],
      dark: blue['50']
    }
  }
});
export const materialUiThemeAr = createMuiTheme({
  overrides: {
    MuiFilledInput: {
      input: {
        textAlign: 'right'
      }
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      formControl: {
        right: 0,
        left: 'auto'
      },
      filled: {
        transform: 'translate(-12px, 24px) scale(1)'
      }
    }
  },
  palette: {
    type: 'dark',
    primary: {
      light: blue['50'],
      main: grey['50'],
      dark: blue['50']
    }
  }
});

export const materialUiThemeLight = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#1E6DAD'
    }
  }
});
export const materialUiThemeLightAr = createMuiTheme({
  overrides: {
    MuiFilledInput: {
      input: {
        textAlign: 'right'
      }
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      formControl: {
        right: 20,
        left: 'auto'
      }
    }
  },
  palette: {
    type: 'light',
    primary: {
      main: '#1E6DAD'
    }
  }
});
export default materialUiTheme;
