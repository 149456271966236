import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, getIn } from 'formik';
import { TextField } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import materialUiTheme, { materialUiThemeAr } from '../materialUiTheme';
import * as Yup from 'yup';
import logo from '../logo-trans.png';
import { useBodyClass, useLangParam } from '../hooks';
import { useTranslation } from 'react-i18next';
import LanguageSelect from './LanguageSelect';
import { ApiContext } from '../context/Api';

function ForgotPassword() {
  useLangParam();
  const { apiService } = useContext(ApiContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { lang } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  useBodyClass('signin');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    if (user) {
      navigate('/');
    }
  }, [navigate]);

  async function onReset(values) {
    setIsLoading(true);

    apiService
      .sendPostRequest('auth/forgot-password', {
        email: values.email,
        url: `${window.location.origin}/reset-password` //todo: this needs to be the url to reset password and verify the code sent to the user via email
      })
      .then(response => {
        // Handle success.
        setIsLoading(false);
        setSuccess('An email has been sent to you');
        console.log('Your user received an email');
      })
      .catch(error => {
        // Handle error.
        setError(error.response.data.message[0].messages[0].message);
        setIsLoading(false);
        console.log('An error occurred:', error.response);
      });
  }
  return (
    <>
      <Container>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 offset-lg-3 col-lg-6 offset-md-2 col-md-8 center-block">
              <div className="top-lang">
                <LanguageSelect showLanguage />
              </div>
              <div className="well-header text-center">
                <img
                  alt=""
                  src={logo}
                  width="80"
                  className="d-inline-block align-top logo"
                />
                <div>
                  <h3 className="heading-title text-center">
                    {t('core.progressingTogether')}
                  </h3>
                </div>
                <div>
                  <h5 className="heading-title">
                    {' '}
                    {t('auth.forgotPassword')}{' '}
                  </h5>
                </div>
              </div>
              <Formik
                initialValues={{ email: '' }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email(t('core.invalidEmail'))
                    .required(t('core.required'))
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setError(null);
                  setSuccess(null);
                  onReset(values);
                  setSubmitting(false);
                }}
              >
                {({ handleChange, errors, touched }) => (
                  <Form className="form-base">
                    <ThemeProvider
                      theme={
                        lang === 'en' ? materialUiTheme : materialUiThemeAr
                      }
                    >
                      <TextField
                        label={t('core.email') + '*'}
                        className="form-field"
                        type="text"
                        name="email"
                        variant="filled"
                        onChange={handleChange}
                        error={Boolean(
                          getIn(touched, 'email') && getIn(errors, 'email')
                        )}
                        helperText={
                          getIn(touched, 'email') && getIn(errors, 'email')
                            ? getIn(errors, 'email')
                            : ''
                        }
                      />
                    </ThemeProvider>
                    {error && <div className="alert alert-danger">{error}</div>}
                    {success && (
                      <div className="alert alert-success">{success}</div>
                    )}

                    {!isLoading ? (
                      <button
                        type="submit"
                        className="btn btn-dark btn-lg btn-block form-btn"
                      >
                        {t('auth.forgotPassword')}
                      </button>
                    ) : (
                      <div
                        type="submit"
                        className="btn btn-dark btn-lg btn-block form-btn disabled"
                      >
                        <FontAwesomeIcon
                          className="fa-spin"
                          size="1x"
                          icon={faSpinner}
                        />
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
              <div className="end-text text-center">
                {t('auth.alreadyHaveAccount') + ' '}
                <Link className="login-link-text" id="login_id" to="../signin">
                  {t('auth.login')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
export default ForgotPassword;
