import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Accordion,
  Card,
  Button,
  ListGroup,
  Col,
  Toast
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCommentAlt,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import '../scss/App.scss';
import { useBodyClass, useLangParam } from '../hooks';
import { ProgressContext } from '../context/Progress';
import { ApiContext } from '../context/Api';
import MainNavbar from './Navbar';
import Loader from './Loader';
import NetworkError from './NetworkError';
import AddToHomescreenPrompt from './AddToHomescreenPrompt';

function Home() {
  useLangParam();
  const { t } = useTranslation();
  const location = useLocation();
  const [currentLang] = useCookies(['language']);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [dataError, setDataError] = useState(false);
  let { lang } = useParams();
  const progress = useContext(ProgressContext);
  const { apiService } = useContext(ApiContext);

  useBodyClass('home');

  const postData = useCallback(
    async language => {
      const data = apiService.getCourses(language);
      if (!data) {
        setError('Something went wrong');
      }
      return data;
    },
    [apiService]
  );

  const fetchData = useCallback(() => {
    setIsLoading(true);
    try {
      const response = postData(lang ? lang : 'ar');
      response
        .then(responseData => {
          setData(responseData.data.courses);
          setIsLoading(false);
          setDataError(false);
        })
        .catch(() => {
          setDataError(true);
          setIsLoading(false);
        });
    } catch (err) {
      setError('Something went wrong');
      setIsLoading(false);
    }
  }, [postData, lang]);

  const onRefresh = useCallback(() => {
    setIsLoading(true);
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const values = queryString.parse(location.search);
    values.msgsent && setShow(true);
    fetchData();
  }, [currentLang, location.search, fetchData]);

  if (error) {
    return error;
  }
  function lessonIcon(id, slug) {
    if (progress && progress.completedLessons.includes(slug)) {
      return <FontAwesomeIcon className="checkIcon" icon={faCheck} />;
    } else {
      return <b className="idIcon">{id}</b>;
    }
  }

  function progressPercent(course) {
    const courseLessonsCompleted = course.lessons.filter(lesson => {
      return progress && progress.completedLessons.includes(lesson.slug);
    });

    return Math.round(
      (courseLessonsCompleted.length / course.lessons.length) * 100
    );
  }

  function introIcon(course) {
    const courseLessonsCompleted = course.lessons.filter(lesson => {
      return progress && progress.completedLessons.includes(lesson.slug);
    });

    return courseLessonsCompleted.length ? (
      <FontAwesomeIcon className="checkIcon" icon={faCheck} />
    ) : (
      <b className="idIcon">i</b>
    );
  }

  function iconUrl(url) {
    // If icon is an absolute URL, just use it
    if (url.indexOf('http') > -1) {
      return url;
    }
    // If icon is relative, use the apiService.cmsUrl since dev is on two different hosts
    // Trim leading/trailing slashes (/) to ensure only one
    return (
      apiService.cmsUrl.replace(/^\/+|\/+$/g, '') +
      '/' +
      url.replace(/^\/+|\/+$/g, '')
    );
  }
  if (dataError) {
    return (
      <>
        <MainNavbar />
        <NetworkError onRefresh={onRefresh} />
      </>
    );
  }
  return (
    <>
      <div className="App">
        <MainNavbar />
        <Link
          type="button"
          to="contact"
          className="btn contact-btn btn-primary btn-circle btn-lg shadow"
        >
          <FontAwesomeIcon icon={faCommentAlt} />
        </Link>
        {!isLoading ? (
          <>
            <Accordion defaultActiveKey="0">
              {data &&
                data.map(course => (
                  <Card key={course.id}>
                    <Card.Header className={`'card-header`}>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={course.id}
                      >
                        <div
                          className={`c100 card-image p${progressPercent(
                            course
                          )}`}
                        >
                          {course.icon && course.icon.url ? (
                            <img
                              alt=""
                              src={iconUrl(course.icon.url)}
                              className={`d-inline-block align-top card-image`}
                            />
                          ) : (
                            <div className={`circleIcon`}></div>
                          )}
                          <div className="slice">
                            <div className="bar"></div>
                            <div className="fill"></div>
                          </div>
                        </div>

                        <h3 className="card-title">{course.name}</h3>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={course.id}>
                      <Card.Body className={`card-body`}>
                        <ListGroup variant="flush">
                          <Link to={course.slug}>
                            <ListGroup.Item>
                              {introIcon(course)}
                              &nbsp; {t('lesson.introduction')}
                            </ListGroup.Item>
                          </Link>
                          {course.lessons.map(lesson => (
                            <div key={lesson.id}>
                              <Link
                                to={`${course.slug}/${lesson.id}-${lesson.slug}`}
                              >
                                <ListGroup.Item>
                                  <div className={`lesson-icon`}>
                                    {lessonIcon(lesson.sortOrder, lesson.slug)}
                                  </div>
                                  <div>
                                    <Col className={`home-lesson-title`}>
                                      {lesson.name}
                                      <p className={`lesson-description`}>
                                        {lesson.subtitle}
                                      </p>
                                    </Col>
                                  </div>
                                </ListGroup.Item>
                              </Link>
                            </div>
                          ))}
                        </ListGroup>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
            </Accordion>
            <Link
              type="button"
              to="contact"
              className="btn btn-primary btn-lg btn-block form-btn"
            >
              {t('core.contact')}
            </Link>
          </>
        ) : (
          <Loader />
        )}
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          className="bg-success text-white"
          autohide
        >
          <Toast.Header>
            <FontAwesomeIcon
              size="2x"
              icon={faCheckCircle}
              className="text-success toast-header-icon"
            />
            <strong className="mr-auto">{t('core.contact')}</strong>
          </Toast.Header>
          <Toast.Body>{t('contact.success')}</Toast.Body>
        </Toast>
        <AddToHomescreenPrompt />
      </div>
    </>
  );
}

export default Home;
