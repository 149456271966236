import React, { useEffect } from 'react';
import {
  Route,
  useLocation,
  useParams,
  Navigate,
  Outlet,
  useMatches,
  createRoutesFromElements
} from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useCookies } from 'react-cookie';
import Home from './components/Home';
import Course from './components/Course';
import Lesson from './components/Lesson';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import Contact from './components/Contact';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import { useChangeLanguage } from './hooks';

const AdminRedirect = () => {
  const adminUrl = process.env.REACT_APP_URL || 'http://localhost:1337/';
  useEffect(() => {
    window.location.href = adminUrl + 'admin';
  }, [adminUrl]);
  return <Navigate to={adminUrl + 'admin'} relative={false} />;
};

const AnalyticsLayout = () => {
  const location = useLocation();
  const routeParams = useParams();
  const matches = useMatches();
  const [cookies] = useCookies(['language']);
  const changeLanguage = useChangeLanguage();

  useEffect(() => {
    const eventParams = {
      screen_name: location.pathname,
      firebase_screen_name: location.pathname
    };
    const { lang, courseSlug, lessonSlug } = routeParams;
    if (lang !== cookies.language) {
      // a URL with a different language param from the cookie is requested,
      // so switch the UI language
      console.log('implicit lang switch', {
        lang,
        cookie: cookies.language
      });
      changeLanguage(lang);
      return;
    }
    if (courseSlug) {
      eventParams.course = courseSlug;
    }
    if (lessonSlug) {
      eventParams.lesson = lessonSlug.substring(lessonSlug.indexOf('-') + 1);
    }

    // match current location against routes
    if (matches && matches.length) {
      const { handle } = matches.slice(-1)[0]; // get last element, which is the final match

      // set screen name from `handle` on route
      if (handle) {
        eventParams.screen_name = handle;
        eventParams.firebase_screen_name = handle;
      }
    }
    // console.log(eventParams);
    logEvent(getAnalytics(), 'screen_view', eventParams);
  }, [location, routeParams, matches, cookies.language, changeLanguage]);

  return <Outlet />;
};
const routeElements = (
  <Route path="/">
    <Route path="/admin">
      <Route index element={<AdminRedirect />} />
      <Route path=":seg1" element={<AdminRedirect />} />
    </Route>
    <Route path="/:lang" element={<AnalyticsLayout />}>
      <Route index element={<Home />} handle="Home" />
      <Route
        path="privacy"
        element={<PrivacyPolicy />}
        handle="PrivacyPolicy"
      />
      <Route
        path="terms"
        element={<TermsConditions />}
        handle="TermsConditions"
      />
      <Route path="signup" element={<SignUp />} handle="SignUp" />
      <Route path="signin" element={<SignIn />} handle="SignIn" />
      <Route path="contact" element={<Contact />} handle="Contact" />
      <Route
        path="forgot-password"
        element={<ForgotPassword />}
        handle="ForgotPassword"
      />
      <Route
        path="reset-password"
        element={<ResetPassword />}
        handle="ResetPassword"
      />

      <Route path=":courseSlug">
        <Route index element={<Course />} handle="Course" />
        <Route path=":lessonSlug" element={<Lesson />} handle="Lesson" />
      </Route>
    </Route>
    <Route index element={<Home />} />
  </Route>
);

const routes = createRoutesFromElements(routeElements);
export default routes;
