import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { set } from 'lodash';

const DEFAULT_QUERY = 'graphql';
const STRAPI_VERSION = 'strapiVersion';

export const ApiContext = React.createContext(null);
const cookies = new Cookies();

export function ApiProvider({ children, i18n }) {
  const [locales, setLocales] = useState([
    {
      code: 'en',
      localName: 'English'
    },
    {
      code: 'ar',
      localName: 'العربية'
    }
  ]);
  const [localesLoaded, setLocalesLoaded] = useState(false);

  const apiService = useMemo(() => new ApiService(), []);

  // onLoad
  useEffect(() => {
    // get locales and translation strings from API
    apiService.getAppTranslations().then(response => {
      // get available
      if (response.data.languages) {
        const apiLocales = response.data.languages.map(lng => ({
          code: lng.i18n_locale.code,
          localName: lng.localName,
          published: !!lng.published_at
        }));
        setLocales(apiLocales);
        setLocalesLoaded(true);
      }

      const resources = {};

      // process each individual translation
      const setTranslation = t => {
        let locale = resources[t.locale];
        if (!locale) {
          locale = resources[t.locale] = { translation: {} };
        }
        set(locale.translation, t.key, t.translation);
      };

      // loop through all translations and each nested localization
      for (const t of response.data.appTranslations) {
        setTranslation(t);

        if (t.localizations) {
          for (const l of t.localizations) {
            setTranslation(l);
          }
        }
      }

      // load all languages
      for (const lng in resources) {
        i18n.addResourceBundle(
          lng,
          'translation',
          resources[lng].translation,
          true,
          true
        );
      }
    });
  }, [apiService, i18n]);

  const context = {
    locales,
    localesLoaded,
    apiService
  };
  return <ApiContext.Provider value={context}>{children}</ApiContext.Provider>;
}

class ApiService {
  constructor() {
    // Use a singleton so there is only ever one instance
    if (ApiService.instance) {
      return ApiService.instance;
    }

    this.strapiVersion = '1.0.0';
    this.cmsUrl = process.env.REACT_APP_URL || 'http://localhost:1337/';
    this.locales = [
      {
        code: 'en',
        localName: 'English'
      },
      {
        code: 'ar',
        localName: 'العربية'
      }
    ];

    this._init();

    ApiService.instance = this;
    return this;
  }

  async _init() {
    // try get Strapi version from cookie
    let version = cookies.get(STRAPI_VERSION);

    // if no cookie, get Strapi version from API response header
    let versionHeader;
    if (!version) {
      try {
        const response = await axios.head(this.cmsUrl);
        versionHeader = response.headers['x-powered-by'];
      } catch (error) {
        // if any error happens, we should still get the headers
        versionHeader = error.response.headers['x-powered-by'];
      } finally {
        if (versionHeader) {
          // expected header format: "Strapi v1.2.3"
          version = versionHeader.replace('Strapi v', '');
        }
      }
    }

    if (version) {
      this.strapiVersion = version;
      cookies.set(STRAPI_VERSION, this.strapiVersion, {
        maxAge: 604800 // 7 days (in sec)
      });
    }
  }

  /**
   * Get all AppTranslations
   * @returns {Promise<*|null>}
   */
  getAppTranslations() {
    return this._queryGraphql(`
          query {
            appTranslations {
              key
              translation
              locale
              localizations {
                key
                translation
                locale
              }
            }
            languages(publicationState: PREVIEW) {
              localName
              translatedName
              i18n_locale {
                code
                name
              }
              published_at
            }
          }`);
  }

  /**
   * Get all Courses for a given language
   * @param language
   * @returns {Promise<*|null>}
   */
  getCourses(language) {
    return this._queryGraphql(`
          query {
            courses(locale: "${language}") {
              id
              name
              slug
              icon {
                url
              }
              lessons {
                sortOrder
                id
                name
                subtitle
                slug
              }
            }
          }`);
  }

  /**
   * Get Course by language and slug
   * @param language
   * @param courseSlug
   * @returns {Promise<null|any>}
   */
  getCourse(language, courseSlug) {
    return this._queryGraphql(`
          query {
            courses (locale: "${language}", where: {slug:"${courseSlug}"}) {
              id
              slug
              name
              subtitle
              description
              lessons (sort:"sortOrder", limit: 1) {
                id
                slug
              }
            }
          }
        `);
  }

  /**
   * Get single lesson by ID
   * @param id
   * @returns {Promise<null|any>}
   */
  getLesson(id) {
    return this._queryGraphql(`
          query {
            lesson (id: "${id}") {
              course {
                slug
              }
              id
              name
              subtitle
              slug
              blocks (sort:"sortOrder:asc") {
                id
                sortOrder
                type
                name
                shouldDisplayName
                content
                audioUrl
              }
            }
          }
        `);
  }

  /**
   * Get user progress data
   * @param user
   * @returns {Promise<null|any>}
   */
  getUserData(user) {
    return this._queryGraphql(
      `
          query {
            userdata (where: {userId:"${user.user.id}"}) {
              id,
              completedLessons,
              userId
            }
          }
        `,
      user.jwt
    );
  }

  /**
   * Save user progress data
   * @param user
   * @param userPostId
   * @param completedLessons
   * @returns {Promise<null|any>}
   */
  saveUserData(user, userPostId, completedLessons) {
    const exists = !!userPostId;
    const mutationName = exists ? 'updateUserdatum' : 'createUserdatum';
    const whereClause = exists ? `where: { id: "${userPostId}" }` : '';
    return this._queryGraphql(
      `
      mutation {
          ${mutationName}(
            input: {
              ${whereClause}
              data: { completedLessons: "${completedLessons.join(
                ','
              )}", userId:"${user.user.id}" }
            }
          ) {
            userdatum {
              completedLessons,
              userId
            }
          }
        }
      `,
      user.jwt
    );
  }

  /**
   * Get a user's answers to a LessonBlock
   * @param user
   * @param user.user.id - User ID
   * @param user.jwt - User JWT
   * @param lessonBlockId
   * @returns {Promise<null|any>}
   */
  getUserAnswers(user, lessonBlockId) {
    const userId = user.user.id;
    const jwt = user.jwt;

    return this._queryGraphql(
      `
          query {
            answers (where: {lessonblock:"${lessonBlockId}", user: "${userId}"}) {
              id,
              answer,
            }
          }
        `,
      jwt
    );
  }

  /**
   * Save/Update a user answer
   * @param user
   * @param blockId
   * @param answerId
   * @param answerContent
   * @returns {Promise<null|any>}
   */
  saveUserAnswer(user, blockId, answerId, answerContent) {
    const exists = answerId !== undefined && answerId !== null;
    const mutationName = exists ? 'updateAnswer' : 'createAnswer';
    const whereClause = exists ? `where: {id:"${answerId}"}` : '';

    return this._queryGraphql(
      `
      mutation {
          ${mutationName}(
            input: {
              ${whereClause}
              data: {
                answer: "${answerContent}",
                lessonblock: "${blockId}",
                user: "${user.user.id}"
              }
            }
          ) {
            answer {
              id
              answer
            }
          }
        }
      `,
      user.jwt
    );
  }

  /**
   * Send email via API
   * @param to
   * @param subject
   * @param body
   * @returns {Promise<void>}
   */
  sendEmail(to, subject, body, options) {
    const config = {
      ...options,
      to,
      subject
    };
    if (body) {
      config.html = body;
    }
    return this.sendPostRequest('send-email', config);
  }

  /**
   * Send generic POST request. Prepends `path` with proper CMS URL
   * @param path
   * @param body
   * @returns {Promise<Promise<AxiosResponse<T>>>}
   */
  sendPostRequest(path, body) {
    return axios.post(`${this.cmsUrl}${path}`, body);
  }

  /**
   * Execute a GraphQL query
   * @param query
   * @returns {Promise<null|any>}
   * @private
   */
  async _queryGraphql(query, auth) {
    const headers = { 'Content-Type': 'application/json' };
    if (auth) {
      headers['Authorization'] = `Bearer ${auth}`;
    }

    const response = await axios.post(
      this.cmsUrl + DEFAULT_QUERY,
      { query },
      { headers }
    );

    const versionHeader = response.headers['x-powered-by'];
    if (versionHeader) {
      const version = versionHeader.replace('Strapi v', '');
      if (version !== this.strapiVersion) {
        this.strapiVersion = version;
      }
    }

    if (response) {
      return response.data;
    } else {
      return null;
    }
  }
}
export default ApiService;
