import Scripture from './Scripture';
import Question from './Question';
import General from './General';
import Sharing from './Sharing';

export default function LessonBlock(props) {
  var component;
  switch (props.block.type) {
    case 'scripture':
      component = Scripture(props);
      break;
    case 'questions':
      component = Question(props);
      break;
    case 'sharing':
      component = Sharing(props);
      break;
    case 'objectives':
    case 'activities':
    case 'application':
    default:
      component = General(props);
      break;
  }
  return component;
}
