import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ar from './language/ar.json';
import en from './language/en.json';

// the translations
const resources = {
  en,
  ar
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ar',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
