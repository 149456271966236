import React from 'react';
import { useTranslation } from 'react-i18next';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { Col, Row } from 'react-bootstrap';
import { CloudOff } from '@material-ui/icons';

export default function NetworkError({ onRefresh }) {
  const { t } = useTranslation();

  return (
    <div className="container-fluid h-100 error-container">
      <PullToRefresh onRefresh={onRefresh}>
        <Row>
          <Col className="align-self-center">
            <CloudOff style={{ fontSize: 200, color: '#7a7a7a' }} />
            <h3 style={{ color: '#7a7a7a' }}>
              {t('core.networkError')} <br />
              {t('core.pullDown')}
            </h3>
          </Col>
        </Row>
      </PullToRefresh>
    </div>
  );
}
