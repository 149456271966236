import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Toast } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Formik, Form, getIn } from 'formik';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  faSpinner,
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import {
  materialUiThemeLight,
  materialUiThemeLightAr
} from '../materialUiTheme';
import { ApiContext } from '../context/Api';
import MainNavbar from './Navbar';

function Contact() {
  const navigate = useNavigate();
  const { apiService } = useContext(ApiContext);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { t, i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(['language']);
  const [show, setShow] = useState(false);
  let { lang } = useParams();

  useEffect(() => {
    const language = cookies.language || 'ar';
    i18n.changeLanguage(language);
    if (!cookies.language) {
      setCookie('language', 'ar', { path: '/' });
    }
  }, [cookies.language, i18n, setCookie]);

  function openModal(success) {
    setShow(true);
    if (success) {
      navigate(`../?msgsent=1`);
    }
  }

  async function onSubmit(values) {
    setIsLoading(true);

    const responses = {};
    responses[t('contact.name')] = values.name;
    responses[t('contact.iAm')] = t(values.i_am);
    responses[t('contact.wouldLike')] = t(values.would_like);
    responses[t('contact.message')] = values.message;

    apiService
      .sendPostRequest('contact-submit', {
        name: values.name,
        email: values.email,
        message: values.message,
        responses
      })
      .then(response => {
        // Handle success.
        setIsLoading(false);
        setSuccess(true);
        openModal(true);
        console.log(response);
      })
      .catch(error => {
        // Handle error.
        setIsLoading(false);
        setError(true);
        openModal();
        console.log('An error occurred:', error.response);
      });
  }

  return (
    <>
      <MainNavbar />
      <Container>
        <div className="container container-margin">
          <div className="row">
            <div className="col-12 offset-lg-2 col-lg-8 center-block">
              <Formik
                initialValues={{
                  i_am: '',
                  name: '',
                  would_like: '',
                  email: ''
                }}
                validationSchema={Yup.object({
                  i_am: Yup.string().required(t('core.required')),
                  would_like: Yup.string().required(t('core.required')),
                  name: Yup.string().required(t('core.required')),
                  email: Yup.string()
                    .email(t('core.invalidEmail'))
                    .required(t('core.required'))
                })}
                onSubmit={(values, { setSubmitting }) => {
                  onSubmit(values);
                  setSubmitting(false);
                }}
              >
                {({ handleChange, handleBlur, errors, touched }) => (
                  <Form>
                    <ThemeProvider
                      theme={
                        lang === 'en'
                          ? materialUiThemeLight
                          : materialUiThemeLightAr
                      }
                    >
                      <FormControl
                        error={!!(touched.i_am && errors.i_am)}
                        component="fieldset"
                        className="form-field"
                      >
                        <FormLabel className="contactTitle" component="legend">
                          {t('contact.iAm')}
                        </FormLabel>
                        <RadioGroup
                          aria-label="i_am"
                          name="i_am"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            className="contactRadio"
                            value="contact.individually"
                            control={<Radio color="primary" />}
                            label={t('contact.individually')}
                          />
                          <FormControlLabel
                            className="contactRadio"
                            value="contact.group"
                            control={<Radio color="primary" />}
                            label={t('contact.group')}
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {touched.i_am && getIn(errors, 'i_am')}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        error={!!(touched.would_like && errors.would_like)}
                        component="fieldset"
                        className="form-field"
                      >
                        <FormLabel className="contactTitle" component="legend">
                          {t('contact.wouldLike')}
                        </FormLabel>
                        <RadioGroup
                          aria-label="would_like"
                          name="would_like"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            className="contactRadio"
                            value="contact.speak"
                            control={<Radio color="primary" />}
                            label={t('contact.speak')}
                          />
                          <FormControlLabel
                            className="contactRadio"
                            value="contact.ask"
                            control={<Radio color="primary" />}
                            label={t('contact.ask')}
                          />
                          <FormControlLabel
                            className="contactRadio"
                            value="contact.discipled"
                            control={<Radio color="primary" />}
                            label={t('contact.discipled')}
                          />
                          <FormControlLabel
                            className="contactRadio"
                            value="contact.meet"
                            control={<Radio color="primary" />}
                            label={t('contact.meet')}
                          />
                          <FormControlLabel
                            className="contactRadio"
                            value="contact.connect"
                            control={<Radio color="primary" />}
                            label={t('contact.connect')}
                          />
                          <FormControlLabel
                            className="contactRadio"
                            value="contact.bePart"
                            control={<Radio color="primary" />}
                            label={t('contact.bePart')}
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {touched.would_like && getIn(errors, 'would_like')}
                        </FormHelperText>
                      </FormControl>
                      <div>
                        <TextField
                          label={t('contact.name')}
                          className="form-field"
                          type="text"
                          name="name"
                          variant="filled"
                          onChange={handleChange}
                          error={!!(touched.name && errors.name)}
                          helperText={touched.name && getIn(errors, 'name')}
                        />
                        <TextField
                          label={t('core.email')}
                          className="form-field"
                          type="text"
                          name="email"
                          variant="filled"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!(touched.email && errors.email)}
                          helperText={touched.email && getIn(errors, 'email')}
                        />
                        <TextField
                          label={t('contact.message')}
                          className="form-field message-box"
                          rows={4}
                          type="text"
                          name="message"
                          variant="filled"
                          multiline
                          onChange={handleChange}
                          error={!!(touched.message && errors.message)}
                          helperText={
                            touched.message && getIn(errors, 'message')
                          }
                        />
                      </div>
                      {!isLoading ? (
                        <button
                          type="submit"
                          className="btn btn-dark btn-lg btn-block form-btn"
                        >
                          {t('contact.send')}
                        </button>
                      ) : (
                        <div
                          type="submit"
                          className="btn btn-dark btn-lg btn-block form-btn disabled"
                        >
                          <FontAwesomeIcon
                            className="fa-spin"
                            size="1x"
                            icon={faSpinner}
                          />
                        </div>
                      )}
                    </ThemeProvider>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Row className="contact-toast">
          <Col>
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              autohide
            >
              {success ? (
                <div className="text-center">
                  <Toast.Header>
                    <FontAwesomeIcon
                      size="2x"
                      icon={faCheckCircle}
                      className="text-success toast-header-icon"
                    />
                    <strong className="mr-auto">{t('core.contact')}</strong>
                  </Toast.Header>
                  <Toast.Body>{t('contact.success')}</Toast.Body>
                </div>
              ) : null}
              {error ? (
                <div className="text-center">
                  <Toast.Header>
                    <FontAwesomeIcon
                      size="2x"
                      icon={faTimesCircle}
                      className="text-danger toast-header-icon"
                    />
                    <strong className="mr-auto">{t('core.contact')}</strong>
                  </Toast.Header>
                  <Toast.Body>{t('contact.failed')}</Toast.Body>
                </div>
              ) : null}
            </Toast>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Contact;
