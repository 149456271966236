import React, { useContext, useMemo } from 'react';
import { compact, uniq } from 'lodash';
import { Cookies } from 'react-cookie';
import { ApiContext } from './Api';

const COMPLETED_LESSONS = 'completedLesson';
const USER_INFO = 'userInfo';
const USER_DATA_POST_ID = 'userDataPostId';

export const ProgressContext = React.createContext(null);
const cookies = new Cookies();

export function ProgressProvider({ children }) {
  const { apiService } = useContext(ApiContext);

  const progressTracker = useMemo(() => new ProgressTracker({ apiService }), [
    apiService
  ]);

  return (
    <ProgressContext.Provider value={progressTracker}>
      {children}
    </ProgressContext.Provider>
  );
}

class ProgressTracker {
  constructor({ apiService }) {
    // Use a singleton so there is only ever one instance
    if (ProgressTracker.instance) {
      return ProgressTracker.instance;
    }

    this.completedLessons = [];
    this.apiService = apiService;

    // initialize from local/db
    this._init();

    ProgressTracker.instance = this;
    return this;
  }

  /**
   * Mark a lesson as completed and persist value
   * @param lessonSlug
   * @returns {Promise<void>}
   */
  completeLesson = async lessonSlug => {
    // Add lesson to list of completed lessons
    if (!this.completedLessons.includes(lessonSlug)) {
      this.completedLessons.push(lessonSlug);
    }
    // Save list to local/db
    await this._saveLocal();
    await this._saveToDatabase();
  };

  /**
   * Reset progress value and persist
   * @returns {Promise<void>}
   */
  reset = async () => {
    this.completedLessons = [];
    this._saveLocal();
    this._saveToDatabase();
  };

  _init = async () => {
    const localLessons = await this._getLocal();
    const userData = await this._getFromDatabase();
    const dbLessons =
      userData && userData.completedLessons
        ? userData.completedLessons.split(',')
        : [];
    if (userData && userData.id) {
      // store the ID of this post to update it in the future easily
      cookies.set(USER_DATA_POST_ID, userData.id, { path: '/' });
    } else {
      cookies.remove(USER_DATA_POST_ID);
    }

    // combine local and db values
    this.completedLessons =
      compact(uniq([...localLessons, ...dbLessons])) || [];
  };

  /**
   * Get completed lessons from local storage
   * @returns {Promise<Array|*>}
   * @private
   */
  _getLocal = async () => {
    const completedLessons = cookies.get(COMPLETED_LESSONS);
    if (completedLessons) {
      return completedLessons.split(',');
    }
    return [];
  };

  /**
   * Save completed lessons to local storage
   * @returns {Promise<void>}
   * @private
   */
  _saveLocal = async () => {
    cookies.set(COMPLETED_LESSONS, this.completedLessons.join(','), {
      path: '/'
    });
  };

  /**
   * Get userData from GraphQL database
   * @param user
   * @param user.jwt
   * @param user.user.id
   * @returns {Promise<any>} UserData object from db
   */
  _getFromDatabase = async () => {
    const user = JSON.parse(localStorage.getItem(USER_INFO));
    if (!user) {
      return null;
    }
    try {
      const responseData = await this.apiService.getUserData(user);
      const userData =
        responseData &&
        responseData.data &&
        responseData.data.userdata &&
        responseData.data.userdata[0];
      return userData;
    } catch (error) {
      // If error also return initialValue
      return error;
    }
  };

  /**
   * Create/Update userData in GraphQL database
   * @param userPostId
   * @param user
   * @param completedLessons
   * @returns {Promise<void>}
   * @private
   */
  _saveToDatabase = async () => {
    const user = JSON.parse(localStorage.getItem(USER_INFO));
    const userPostId = cookies.get(USER_DATA_POST_ID);

    // If user is not logged in, don't save to db
    if (!user) {
      return;
    }

    await this.apiService.saveUserData(user, userPostId, this.completedLessons);
  };
}
export default ProgressTracker;
