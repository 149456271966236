import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAnalytics, setUserProperties } from 'firebase/analytics';

function useChangeLanguage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['language']);
  const analytics = getAnalytics();

  function changeLanguage(language) {
    if (cookies.language !== language) {
      setUserProperties(analytics, { language });

      setCookie('language', language, { path: '/' });

      const currentUrl = location.pathname;
      let newUrl =
        currentUrl.length > 3
          ? currentUrl.replace(`/${cookies.language}/`, `/${language}/`)
          : currentUrl.replace(`/${cookies.language}`, `/${language}`);
      navigate(newUrl, { relative: false });
    }
  }

  return changeLanguage;
}
export default useChangeLanguage;
