import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import md from '../../markdown';

export default function Scripture({ block }) {
  function audioPlay(event) {
    let audio = event.currentTarget.nextElementSibling;
    if (!audio.className) {
      audio.classList.add('d-none');
      audio.pause();
    } else {
      audio.classList.remove('d-none');
      audio.play();
    }
  }

  return (
    <div key={block.id} className="paragraph-section">
      {block.shouldDisplayName ? (
        <Container>
          <Row>
            <div
              className="scripture-name"
              data-name={block.name}
              data-id={block.id}
              dangerouslySetInnerHTML={{
                __html: md.makeHtml(block.name)
              }}
            />
            {block.audioUrl ? (
              <div className="audioIcon" onClick={audioPlay}>
                <FontAwesomeIcon icon={faVolumeUp} />
              </div>
            ) : null}
            <audio className="d-none" controls>
              <source src={block.audioUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </Row>
        </Container>
      ) : null}
      <div
        className="paragraph-item"
        dangerouslySetInnerHTML={{
          __html: md.makeHtml(block.content)
        }}
      />
    </div>
  );
}
