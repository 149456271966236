import React from 'react';

const SvgActivities = props => (
  <svg viewBox="0 0 25 21" fill="none" {...props}>
    <path
      d="M12.2 9.16a.4.4 0 00.4-.4V6a.4.4 0 00-.4-.4c-.992 0-1.8-.808-1.8-1.8S11.208 2 12.2 2a.4.4 0 00.4-.4V.4a.4.4 0 00-.4-.4H3.4a.4.4 0 00-.4.4v8.36c0 .22.18.4.4.4h1.83a2.604 2.604 0 002.57 2.2 2.604 2.604 0 002.57-2.2h1.83zM22.883 13.54l-.87-.87c.324-.443.5-.976.5-1.535 0-.694-.271-1.347-.762-1.838a2.582 2.582 0 00-1.838-.762c-.559 0-1.092.176-1.534.5L16.66 7.318a.4.4 0 00-.566 0l-1.866 1.867a.4.4 0 000 .566c.34.34.527.792.527 1.273 0 .48-.188.933-.527 1.273-.34.34-.792.526-1.273.526a1.79 1.79 0 01-1.273-.526.4.4 0 00-.566 0l-.933.933a.399.399 0 000 .566l6.222 6.222a.399.399 0 00.566 0l5.911-5.911a.402.402 0 000-.566z"
      fill="#000"
    />
  </svg>
);

export default SvgActivities;
