import React from 'react';
export default function NotFound() {
  return (
    <div className="col-md-12 text-center">
      <div className="error-template">
        <h1>Oops!</h1>
        <h2>404 Not Found</h2>
        <div className="error-details">Sorry, that page was not found!</div>
        <div className="error-actions">
          <a href="/" className="btn btn-primary btn-lg">
            <span className="glyphicon glyphicon-home"></span>
            Home
          </a>
        </div>
      </div>
    </div>
  );
}
