import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import logo from '../logo-trans.png';

function AddToHomescreenPrompt() {
  const { t } = useTranslation();

  return (
    <div id="a2hsp" className="ad2hs-prompt">
      <img src={logo} width="20" height="20" alt="Progressing Together logo" />
      <button className="add-prompt">{t('core.addToHome')}</button>
      <button className="dismiss-prompt">
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
}

export default AddToHomescreenPrompt;
