import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { ApiContext } from '../context/Api';

function useLangParam() {
  const { locales, localesLoaded } = useContext(ApiContext);
  let { lang } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(['language']);

  let currentLanguage = cookies.language || 'ar';

  if (localesLoaded) {
    const validLanguageCodes = locales.map(l => l.code);
    // first, make sure we have a valid language in case the cookie is bad
    if (!validLanguageCodes.includes(currentLanguage)) {
      currentLanguage = 'ar';
    }

    // if URL param doesn't match cookie, update the cookie
    if (lang && lang !== cookies.language) {
      setCookie('language', lang, { path: '/' });
    }

    // make sure the i18n language matches the language cookie
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }

    // if there's no language param, redirect to same URL with language param
    if (!lang || !validLanguageCodes.includes(lang)) {
      // redirect by prefixing current path with language code
      navigate(`/${currentLanguage}${pathname}`, {
        replace: true,
        relative: false
      });
    }
  }
}
export default useLangParam;
